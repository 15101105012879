var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Breadcrumbs',{attrs:{"items":_vm.breadcrumbs,"page_title":_vm.editLibraryData.topicName},on:{"ShowAction":_vm.ShowAction}}),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-5"},_vm._l((_vm.cardItems),function(item,i){return _c('v-col',{key:i,attrs:{"cols":"12","xs":"12","sm":"12","md":"4","lg":"2"}},[_c('v-card',{staticStyle:{"border-radius":"16px"},attrs:{"height":"200"},on:{"click":function($event){item.title == _vm.$t('video') && _vm.$store.getters.showLibraryAction
              ?  _vm.gotoCourseVideoList()
              : _vm.$router.push({
                path: '/home/library/' +
                    _vm.editLibraryData.topicName.split('?').join('') +
                    '/' +
                    item.name,
                query: {topic_name : _vm.editLibraryData.topicName}
              })}}},[_c('v-card-text',{staticStyle:{"height":"100%"},attrs:{"justify":"center","align":"center"}},[_c('div',{staticClass:"imgtext"}),_c('v-img',{staticClass:"communityimg",attrs:{"src":item.image,"width":"52"}}),_c('p',{staticClass:"text-center mt-5",staticStyle:{"font-size":"18px","color":"#424242"}},[_vm._v(_vm._s(item.title))])],1)],1)],1)}),1),(_vm.$store.getters.showLibraryAction)?_c('cancel-save'):_vm._e()],1),_c('SuccessDialog',{attrs:{"show":_vm.successDialog1,"title":_vm.$t('success'),"text":_vm.message},on:{"close":function($event){(_vm.successDialog1 = false), _vm.getData()}}}),_c('PermissionDialog',{attrs:{"show":_vm.permissionDialog,"title":"Error","text":_vm.permissionMessage},on:{"close":function($event){_vm.permissionDialog = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }